
































import Vue from "vue";
import { api } from "@/util/axios";
import moment from "moment-timezone";
import ReportDateFilter from "./ReportDateFilter2.vue";

const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";
export default Vue.extend<any, any, any, any>({
  name: "TopFilter",
  components: {
    ReportDateFilter,
  },

  props: {
    cardView: {
      default: false,
    },
  },
  data: () => ({
    startDate: null,
    endDate: null,
    applyDateFilter: true,
    applyFilter: [],
    filterStart: null,
    filterEnd: null,
    params: {},
    stats: 0,
    statsTitle: "",
  }),
  methods: {
    getParams() {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);

      this.params = {
        method: params.get("method"),
        startDate: params.get("startDate"),
        endDate: params.get("endDate"),
        type: params.get("type"),
        staff: params.get("staff"),
      };
    },

    toggleFilter() {
      if (this.applyDateFilter) {
        this.applyDateFilter = true;
      } else {
        this.applyDateFilter = false;
      }
    },
    onRangeChange(filters) {
      this.filterStart = filters.start;
      this.filterEnd = filters.end;

      if (this.applyDateFilter) {
        this.startDate = filters.start;
        this.endDate = filters.end;
        this.fetchSalesReports();
        this.$emit("dateChanged", {
          startDate: this.startDate,
          endDate: this.filterEnd,
        });
      }
    },
    fetchSalesReports() {
      const reportType = this.params.type;
      if (reportType == "all sale") {
        this.statsTitle = "All Sales";
        this.fetchSalesReportAllSales("All Sales");
      } else if (reportType == "product") {
        this.statsTitle = "Product Sales";
        this.fetchSalesReportAllSales("Products");
      } else if (reportType == "service") {
        this.statsTitle = "Services Sales";
        this.fetchSalesReportAllSales("Services");
      } else if (reportType == "service package") {
        this.statsTitle = "Service package Sales";
        this.fetchSalesReportAllSales("Service Packages");
      } else if (reportType == "membership") {
        this.statsTitle = "Membership Sales";
        this.fetchSalesReportAllSales("Memberships");
      } else if (reportType == "voucher") {
        this.statsTitle = "Voucher Sales";
        this.fetchSalesReportAllSales("Vouchers");
      }

      if (this.params.staff == "service&paymentStatus=completed&type=service") {
        this.statsTitle = `Staff Revenue`;
        this.fetchSalesReportStaff();
      }

      const method = this.params.method;
      if (method) {
        this.statsTitle = `${method} payments`;
        this.fetchSalesReportPayment(method);
      }
    },
    fetchSalesReportStaff() {
      const businessId = this.$store.getters.role.business._id;
      let apiUrl = `/v1/sales-ledger/staff-revenue?businessId=${businessId}`;

      if (this.employee) {
        apiUrl += `&staff=${this.employee.eId}`;
      }

      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        apiUrl += `&startDate=${date.toISOString()}`;
      }

      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        apiUrl += `&endDate=${date.toISOString()}`;
      }

      api.get(apiUrl).then((response) => {
        this.stats = response.data.reports;
      });
    },

    fetchSalesReportPayment(type) {
      const businessId = this.$store.getters.role.business._id;
      let apiUrl = `/v1/sales-ledger/payments?businessId=${businessId}`;

      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        apiUrl += `&startDate=${date.toISOString()}`;
      }
      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        apiUrl += `&endDate=${date.toISOString()}`;
      }

      api.get(apiUrl).then((response) => {
        const stats = response.data.reports
          .map((x) => {
            x.report_name = x._id;
            x.value = x.totalAmount;
            return x;
          })
          .find((x) => x._id == type);

        if (stats) {
          this.stats = stats.totalAmount;
        }
      });
    },

    fetchSalesReportAllSales(type) {
      const businessId = this.$store.getters.role.business._id;

      let apiUrl = `/v1/sales-ledger/sales-report?businessId=${businessId}`;
      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        apiUrl += `&startDate=${date.toISOString()}`;
      }
      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        apiUrl += `&endDate=${date.toISOString()}`;
      }
      api.get(apiUrl).then((response) => {
        const stats = response.data.reports
          .map((x) => {
            x.report_name = x.report_type;
            return x;
          })
          .find((x) => x.report_name == type);

        if (stats) {
          this.stats = stats.value;
        }
      });
    },
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
    clear() {
      this.startDate = null;
      this.endDate = null;
    },
    setFilters(value) {
      this.applyDateFilters = value;
    },
    navigate(item) {
      const query: any = {
        tab: "orders",
        type: item.report_name.toLowerCase().slice(0, -1),
      };
      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        query.startDate = date.toISOString();
      }
      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        query.endDate = date.toISOString();
      }
      this.$router.push({
        path: "/products",
        query,
      });
    },
  },
  watch: {
    applyDateFilter(x) {
      if (!x) {
        this.starDate = null;
        this.endDate = null;
        this.$emit("dateChanged", {
          startDate: this.startDate,
          endDate: this.endDate,
        });
      } else {
        this.startDate = this.filterStart;
        this.endDate = this.filterEnd;
        this.$emit("dateChanged", {
          startDate: this.startDate,
          endDate: this.endDate,
        });
      }
    },
    startDate() {
      this.fetchSalesReports();
    },
    endDate() {
      this.fetchSalesReports();
    },
  },
  mounted() {
    console.log("mounted");
    this.getParams();
    this.fetchSalesReports();
  },
});
