

























































































































import Vue from "vue";

import OrderModule from "@/store/modules/order";
import ClientModule from "@/store/modules/client";
import { createNamespacedHelpers } from "vuex";
import { Business, Client, Role } from "@/types";
import TopFilter from "../reports/TopFilterLean.vue";

import FeedbackList from "../client/FeedbackList.vue";

// import ReportDateFilter from "../reports/ReportDateFilter.vue";

export default Vue.extend<any, any, any, any>({
  name: "OrderList",
  components: {
    TopFilter,
    FeedbackList,
  },
  props: {
    orderType: {
      type: String,
      default: "product",
    },
  },
  data: () => ({
    rs: ["Reports", "New Clients"],
    feedbackType: "all",
    options: {} as { page: number; itemsPerPage: number },
    isLoading: false,
    startDate: null,
    endDate: null,
    filters: {
      search: "",
      status: "all",
      email: "",
    },
    params: {},
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    title() {
      return this.params.type ? this.camelCase(this.params.type) : "report";
    },
  },

  mounted() {
    this.getParams();
  },
  created() {
    this.getParams();
  },
  methods: {
    customFilter(item: any, queryText: string) {
      const email = item.email.toLowerCase();
      const searchText = queryText.toLowerCase();
      return email.indexOf(searchText) > -1;
    },
    camelCase(str) {
      // Using replace method with regEx
      return str.replace(/\b\w/g, (x) => x.toUpperCase());
    },

    navigateBack() {
      window.history.back();
    },
    getParams() {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);

      let userType = "all";
      if (`${params.get("type")}`.includes("good rating")) {
        userType = "good";
      } else if (`${params.get("type")}`.includes("bad rating")) {
        userType = "bad";
      } else if (`${params.get("type")}`.includes("neutral")) {
        userType = "neutral";
      } else if (`${params.get("type")}`.includes("all")) {
        userType = "all";
      }

      this.feedbackType = userType;
      this.params = {
        method: params.get("method"),
        startDate: params.get("startDate"),
        endDate: params.get("endDate"),
        type: userType,
        staff: params.get("staff"),
      };
    },
    onRangeChange(filters) {
      this.getParams();

      this.params = { ...this.params, ...filters };
      this.startDate = filters.startDate;
      this.endDate = filters.endDate;
    },

    row_classes(item: any) {
      return item.read == false ? "light-green lighten-5" : "";
    },
    getProductsLinks(products: any[]) {
      return products.map((item) => {
        const options = item.options;
        return { title: item.product.title, id: item.product._id, options };
      });
    },
    deleteOrder(orderId: string) {
      this.dropOrder(orderId);
    },
    selectOrderStatus(status: string) {
      this.filters.status = status;
    },
    remove(item: any) {
      //
    },
    exportCsv() {
      this.downloadCsv(
        this.filteredData.map((x) => {
          return {
            "Client ID": x._id,
            "Full Name": x.fullName,
            Email: x.email,
            Rating: x.rating,
            "Created At": x.createdAt,
          };
        }),
        "client_ratings.csv"
      );
    },
    downloadCsv(data: Record<string, any>[], filename = "export.csv") {
      // Get headers from the first object's keys
      const headers = Object.keys(data[0]);

      // Create CSV rows
      const csvRows = [
        // Header row
        headers.join(","),
        // Data rows
        ...data.map((row) =>
          headers
            .map((header) => {
              const cell = row[header];
              // Handle cell content that needs quotes (contains commas or quotes)
              if (
                typeof cell === "string" &&
                (cell.includes(",") || cell.includes('"'))
              ) {
                return `"${cell.replace(/"/g, '""')}"`;
              }
              return cell?.toString() ?? "";
            })
            .join(",")
        ),
      ];

      // Combine rows with newlines
      const csvContent = csvRows.join("\n");

      // Create blob and download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ORDER_STORE")) {
      this.$store.registerModule("ORDER_STORE", OrderModule);
    }
    if (!this.$store.hasModule("ORDER_CLIENTS")) {
      this.$store.registerModule("ORDER_CLIENTS", ClientModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("ORDER_STORE");
    this.$store.unregisterModule("ORDER_CLIENTS");
  },
});
