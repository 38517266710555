







































































































import Vue from "vue";
import moment from "moment-timezone";

export default Vue.extend<any, any, any, any>({
  name: "ReportDateFilter",
  data: () => ({
    displayRange: "All Data",
    currentRangeType: "all",
    currentDate: moment(),
    startDate: null,
    endDate: null,
    type: "all",
    activeRange: null, // Default value for the button toggle
    start: {},
    end: {},
    startDateMenu: false,
    endDateMenu: false,
  }),
  computed: {
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format("YYYY-MM-DD") : "";
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format("YYYY-MM-DD") : "";
    },
  },
  mounted() {
    this.$emit("rangeChange", {
      start: null,
      end: null,
    });
  },
  watch: {
    activeRange(v) {
      if (this.type === "all") {
        this.type = v;
      }
      this.currentRangeType = v;
      this.updateDateRange();
    },
  },
  methods: {
    handleAllClick() {
      this.type = "all";
      this.startDate = null;
      this.endDate = null;
      this.resetToAllData();
    },
    resetToAllData() {
      this.startDate = null;
      this.endDate = null;
      this.displayRange = "All Data";
      this.start = {};
      this.end = {};
      this.$emit("rangeChange", {
        start: null,
        end: null,
      });
    },
    navigateNext() {
      switch (this.currentRangeType) {
        case "month":
          this.currentDate.add(1, "month");
          break;
        case "week":
          this.currentDate.add(1, "week");
          break;
        case "day":
          this.currentDate.add(1, "day");
          break;
      }
      this.updateDateRange();
    },
    navigatePrevious() {
      switch (this.currentRangeType) {
        case "month":
          this.currentDate.subtract(1, "month");
          break;
        case "week":
          this.currentDate.subtract(1, "week");
          break;
        case "day":
          this.currentDate.subtract(1, "day");
          break;
      }
      this.updateDateRange();
    },
    updateDateFromPicker(type: "start" | "end") {
      if (type === "start") {
        this.currentDate = moment(this.startDate);
      } else {
        this.currentDate = moment(this.endDate);
      }

      // Update display range without changing the mode
      this.updateDisplayRange();
      this.syncDatesToObjects();
      this.emitDateRange();
    },
    updateDisplayRange() {
      if (this.startDate && this.endDate) {
        const start = moment(this.startDate);
        const end = moment(this.endDate);

        if (start.isSame(end, "day")) {
          this.displayRange = start.format("MMMM Do YYYY");
        } else {
          this.displayRange = `${start.format("MMMM Do YYYY")} - ${end.format(
            "MMMM Do YYYY"
          )}`;
        }
      } else if (this.type === "all" && !this.startDate && !this.endDate) {
        this.displayRange = "All Data";
      }
    },
    syncDatesToObjects() {
      if (this.startDate) {
        this.start.date = moment(this.startDate).format("YYYY-MM-DD");
        this.start.day = moment(this.startDate).date();
        this.start.year = moment(this.startDate).year();
      } else {
        this.start = {};
      }
      if (this.endDate) {
        this.end.date = moment(this.endDate).format("YYYY-MM-DD");
        this.end.day = moment(this.endDate).date();
        this.end.year = moment(this.endDate).year();
      } else {
        this.end = {};
      }
    },
    emitDateRange() {
      const startDate = this.startDate
        ? moment(this.startDate).startOf("day").toISOString()
        : null;

      const endDate = this.endDate
        ? moment(this.endDate).endOf("day").toISOString()
        : null;
      this.$emit("rangeChange", {
        start: startDate,
        end: endDate,
      });
    },
    updateDateRange() {
      switch (this.currentRangeType) {
        case "month":
          this.startDate = this.currentDate
            .clone()
            .startOf("month")
            .format("YYYY-MM-DD");
          this.endDate = this.currentDate
            .clone()
            .endOf("month")
            .format("YYYY-MM-DD");
          this.displayRange = moment(this.startDate).format("MMMM YYYY");
          break;
        case "week":
          this.startDate = this.currentDate
            .clone()
            .startOf("isoWeek")
            .format("YYYY-MM-DD");
          this.endDate = this.currentDate
            .clone()
            .endOf("isoWeek")
            .format("YYYY-MM-DD");
          this.displayRange = `${moment(this.startDate).format(
            "MMMM Do YYYY"
          )} - ${moment(this.endDate).format("MMMM Do YYYY")}`;
          break;
        case "day":
          this.startDate = this.currentDate
            .clone()
            .startOf("day")
            .format("YYYY-MM-DD");
          this.endDate = this.currentDate
            .clone()
            .endOf("day")
            .format("YYYY-MM-DD");
          this.displayRange = moment(this.startDate).format("MMMM Do YYYY");
          break;
      }
      this.syncDatesToObjects();
      this.emitDateRange();
    },
  },
});
