





















































































































import Vue from "vue";

import OrderModule from "@/store/modules/order";
import ClientModule from "@/store/modules/client";
import { createNamespacedHelpers } from "vuex";
import { Business, Client, Role } from "@/types";
import TopFilter from "../reports/TopFilterLean.vue";

import NewClientList from "../client/NewClientList.vue";

export default Vue.extend<any, any, any, any>({
  name: "OrderList",
  components: {
    TopFilter,
    NewClientList,
  },
  data: () => ({
    rs: ["Reports", "Sales"],
    options: {} as { page: number; itemsPerPage: number },
    isLoading: false,
    startDate: null,
    endDate: null,
    filters: {
      search: "",
      status: "all",
      email: "",
    },
    params: {},
    newClients: [],
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    title() {
      return this.params.type ? this.camelCase(this.params.type) : "report";
    },
  },
  mounted() {
    this.fetchNewClients();
    this.getParams();
  },
  created() {
    this.getParams();
  },
  methods: {
    customFilter(item: any, queryText: string) {
      const email = item.email.toLowerCase();
      const searchText = queryText.toLowerCase();
      return email.indexOf(searchText) > -1;
    },
    camelCase(str) {
      return str.replace(/\b\w/g, (x) => x.toUpperCase());
    },
    navigateBack() {
      window.history.back();
    },
    getParams() {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);

      this.params = {
        method: params.get("method"),
        startDate: params.get("startDate"),
        endDate: params.get("endDate"),
        type: params.get("type"),
        staff: params.get("staff"),
      };
    },
    async fetchNewClients() {
      const businessId = "657aba0ecb7ead0025b592fa";
      const startDate = this.startDate || "2025-01-31T21:00:00.000Z";
      const endDate = this.endDate || "2025-02-28T20:59:59.059Z";
      const url = `/v1/sales-ledger/new-clients?businessId=${businessId}&startDate=${startDate}&endDate=${endDate}`;

      try {
        const response = await this.$axios.get(url);
        this.newClients = response.data.newClients.docs;
      } catch (error) {
        console.error("Error fetching new clients:", error);
      }
    },
    onRangeChange(filters) {
      this.getParams();
      this.params = { ...this.params, ...filters };
      this.startDate = filters.startDate;
      this.endDate = filters.endDate;
    },
    selectOrderStatus(status: string) {
      this.filters.status = status;
    },
    remove(item: any) {
      //
    },
    exportCsv() {
      this.downloadCsv(
        this.newClients.map((x) => {
          return {
            "Client ID": x._id,
            "Full Name": x.fullName,
            Email: x.email,
            Gender: x.gender,
            "Created At": x.createdAt,
          };
        }),
        "new_clients.csv"
      );
    },
    downloadCsv(data: Record<string, any>[], filename = "export.csv") {
      const headers = Object.keys(data[0]);
      const csvRows = [
        headers.join(","),
        ...data.map((row) =>
          headers
            .map((header) => {
              const cell = row[header];
              if (
                typeof cell === "string" &&
                (cell.includes(",") || cell.includes('"'))
              ) {
                return `"${cell.replace(/"/g, '""')}"`;
              }
              return cell?.toString() ?? "";
            })
            .join(",")
        ),
      ];
      const csvContent = csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ORDER_STORE")) {
      this.$store.registerModule("ORDER_STORE", OrderModule);
    }
    if (!this.$store.hasModule("ORDER_CLIENTS")) {
      this.$store.registerModule("ORDER_CLIENTS", ClientModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("ORDER_STORE");
    this.$store.unregisterModule("ORDER_CLIENTS");
  },
});
