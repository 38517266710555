











































import Vue from "vue";
import { api } from "@/util/axios";
import EmployeeMiniSearch from "../employee/EmployeeSearch.vue";
import ReportDateFilter from "./ReportDateFilter.vue";
import employee from "@/store/modules/employee";
import { watch } from "vue";
export default Vue.extend<any, any, any, any>({
  name: "EmployeeReportList",
  components: {
    EmployeeMiniSearch,
    ReportDateFilter,
  },
  data: () => ({
    headers: [
      { text: "Type", value: "name", sortable: false },
      { text: "Sales", value: "value", sortable: false },
      { text: "actions", value: "actions", sortable: false },
    ],
    reports: [
      {
        name: "Staff Revenue",
        value: 0,
      },
      {
        name: "Staff attendance Rate",
        value: "N/A",
      },
    ],
    startDate: null,
    endDate: null,
    employee: null,
    applyDateFilter: true,
    filterStart: null,
    filterEnd: null,
  }),
  watch: {
    startDate() {
      this.fetchSalesReports();
    },
    endDate() {
      this.fetchSalesReports();
    },
    employee() {
      this.fetchSalesReports();
    },
    applyDateFilter(x) {
      if (!x) {
        this.starDate = null;
        this.endDate = null;
      } else {
        this.startDate = this.filterStart;
        this.endDate = this.filterEnd;
      }
    },
  },
  methods: {
    onRangeChange(filters) {
      this.filterStart = filters.start;
      this.filterEnd = filters.end;

      if (this.applyDateFilter) {
        this.startDate = filters.start;
        this.endDate = filters.end;
      }
    },
    fetchSalesReports() {
      const businessId = this.$store.getters.role.business._id;
      let apiUrl = `/v1/sales-ledger/staff-revenue?businessId=${businessId}`;

      if (this.employee) {
        apiUrl += `&staff=${this.employee.eId}`;
      }

      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        apiUrl += `&startDate=${date.toISOString()}`;
      }

      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        apiUrl += `&endDate=${date.toISOString()}`;
      }

      api.get(apiUrl).then((response) => {
        this.reports[0].value = response.data.reports;
      });
    },
    clear() {
      this.startDate = null;
      this.endDate = null;
      this.employee = null;
    },

    navigate(item) {
      let typex = "service&paymentStatus=completed&type=service";
      if (this.employee) {
        typex += `&staff=${this.employee.eId}`;
      }
      const query: any = {
        tab: "orders",
        staff: typex,
      };
      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        query.startDate = date.toISOString();
      }
      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        query.endDate = date.toISOString();
      }
      this.$router.push({
        path: "/reports/report-detail",
        query,
      });
    },
    filterByEmployee(em) {
      this.employee = em;
    },
  },
  mounted() {
    console.log("mounted");
    this.fetchSalesReports();
  },
});
