

































import Vue from "vue";
import { api } from "@/util/axios";
import ReportDateFilter from "./ReportDateFilter.vue";
export default Vue.extend<any, any, any, any>({
  components: {
    ReportDateFilter,
  },
  name: "PaymentReportList",
  data: () => ({
    headers: [
      { text: "Type", value: "report_name", sortable: false },
      { text: "Amount", value: "value", sortable: false },
      { text: "Count", value: "count", sortable: false },
      { text: "actions", value: "actions", sortable: false },
    ],
    reports: [
      { report_name: "All Payments", value: 0 },
      { report_name: "Cash Payments", value: 0 },
      { report_name: "Paypal Payments", value: 0 },
      { report_name: "Mpesa Payments", value: 0 },
      { report_name: "Card Payments", value: 0 },
    ],
    startDate: null,
    endDate: null,
    applyDateFilter: true,
    filterStart: null,
    filterEnd: null,
  }),
  watch: {
    applyDateFilter(x) {
      if (!x) {
        this.starDate = null;
        this.endDate = null;
      } else {
        this.startDate = this.filterStart;
        this.endDate = this.filterEnd;
      }
    },
    startDate() {
      this.fetchSalesReports();
    },
    endDate() {
      this.fetchSalesReports();
    },
  },
  methods: {
    onRangeChange(filters) {
      this.filterStart = filters.start;
      this.filterEnd = filters.end;

      if (this.applyDateFilter) {
        this.startDate = filters.start;
        this.endDate = filters.end;
      }
    },
    fetchSalesReports() {
      const businessId = this.$store.getters.role.business._id;
      let apiUrl = `/v1/sales-ledger/payments?businessId=${businessId}`;

      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        apiUrl += `&startDate=${date.toISOString()}`;
      }
      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        apiUrl += `&endDate=${date.toISOString()}`;
      }

      api.get(apiUrl).then((response) => {
        this.reports = response.data.reports.map((x) => {
          x.report_name = x._id;
          x.value = x.totalAmount;
          return x;
        });
      });
    },
    clear() {
      this.startDate = null;
      this.endDate = null;
    },
    navigate(item) {
      const query: any = { tab: "orders", method: item.report_name };
      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        query.startDate = date.toISOString();
      }
      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        query.endDate = date.toISOString();
      }
      this.$router.push({
        path: "/reports/report-detail",
        query,
      });
    },
  },

  mounted() {
    console.log("mounted");
    this.fetchSalesReports();
  },
});
